import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import '../index.scss';
import './guides.scss';
import { Row, Col } from 'react-bootstrap';
import { SoloArtifact } from '../../../modules/solo/common/components/solo-artifact';
import { SoloJewelry } from '../../../modules/solo/common/components/solo-jewelry';

const SoloGuidesJinwoo: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page solo solo-guide'} game="solo">
      <ul className="breadcrumb">
        <li>
          <Link to="/solo-leveling/">Solo Leveling: Arise</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/solo-leveling/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Best stats for Sung Jinwoo</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/solo/categories/category_stats.jpg"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Best stats and equipment for Jinwoo</h1>
          <h2>
            The best stats and equipment you should invest into on Sung Jinwoo
            in Solo Leveling: Arise.
          </h2>
          <p>
            Last updated: <strong>17/11/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Best Stats for Sung Jinwoo" />
        <StaticImage
          src="../../../images/solo/generic/beginner_stats.webp"
          alt="Tier List"
        />
        <p>
          It’s also important to properly allocate your stat points, as stats
          were not made equal.
        </p>
        <p>
          Players should focus mostly on{' '}
          <strong>Strength and Perception</strong> stats, as they are
          universally useful regardless of the weapon. How much Perception you
          need depends on the dupes of your weapons - since dupes will give you
          additional Precision, a stat that influences the minimum amount of
          damage when attacking. A maxed out SSR weapon provides 4000 points of
          Precision (2000 for the first dupe, 500 afterward) while a maxed SR
          weapon provides 2000.
        </p>
        <StaticImage
          src="../../../images/solo/generic/beginner_weapons.webp"
          alt="Guide"
        />
        <p>
          You can start out with a <strong>4:1 STR/Perception build</strong> and
          reset your stats as you get more dupes in your favorite weapons to get
          more points of STR.
        </p>
        <SectionHeader title="Current Meta Builds" />
        <h5>DPS Sung Jinwoo</h5>
        <p>
          When it comes to building Sung Jinwoo as a DP, it is currently
          preferred to go for a{' '}
          <strong>4 piece Curse set for the armor slots</strong>, which lets you
          equip <strong>the Expert set in the Jewelry slots</strong>. That Build
          will outshine every other option for Scoring contents:
        </p>
        <ul>
          <li>
            For PoD, the build is uncontested as long as you manual but if you
            are not interested in the ranking and can reach 1 billion damage
            with Auto, then the Support Build can work fine too;
          </li>
          <li>
            In Guild Raid, this set vastly outperforms the Support Build but{' '}
            <strong>
              only if your Weapons and Skills are highly invested in
            </strong>
            . If you’re using weapons like Demon King’s Daggers (Dkd) or playing
            with the Sonic Stream - Magnifying Slash - at a high enough rarity
            then this is the build to go for;
          </li>
          <li>
            In high difficulty challenges like WoBL, the build is great but you{' '}
            <strong>need enough TP to Crit consistently</strong>.
          </li>
        </ul>
        <p>
          Another viable option when those conditions are not met is the{' '}
          <strong>8 piece Curse set</strong>, as its Damage increase relies on
          time spent in the fight rather than Crits.
        </p>
        <p>
          For the Skills, you’ll be using the Wind Armor Break + the best Damage
          Skill of the appropriate element, or you can simply opt to run Sonic
          Stream - Magnifying Slash - for just about any fight that is not
          resistant to Dark Damage. Exceptions would be Wind if you’re focusing
          on a maxed out Dkd, and Light where you can use the Light version of
          Sonic Stream - Double Down -. Dagger Rush is also an amazing skill to
          use, especially the wind/dark version.
        </p>
        <p>
          On the side of Blessing Stones, you can now pick 4 Offensive Stones
          and still have room for Defensive options, so pick your Highest rarity
          Stones among the ones increasing your Damage/Attack, not forgetting to
          include Blood Lust in the mix as its effect is just that powerful.
        </p>
        <h5>Support Sung Jinwoo</h5>
        <p>
          With the release of guild boss, we started to see the rise of a new
          build, the support build. This build revolves around supporting the
          hunters in your team with the use of support blessings and skills,
          massively increasing the overall team dps. When running support SJW,
          you will be using the <strong>Burning Blessing Set</strong>. As SJW
          doesn't have directly any support for the team, this is currently his
          best option for this kind of build. When running this set you will
          also run the <strong>Fire</strong> version of Crushing Blow, since it
          will massively help fill your power gauge. The second skill is going
          to be the usual <strong>Wind</strong> Armor Break, for the invaluable
          damage increase debuff. If you don't have Armor Break at heroic or
          above, you can also use Commander's Touch - Black Hole - as it can
          inflict a powerful def down debuff on the target. It's less useful
          compared to Armor Break, but can act as a replacement for the time
          being.
        </p>
        <p>
          As you will be using skills non-stop to refresh your power gauge, when
          running support SJW you will be running a full INT build to reduce
          your skill CD and increase your available mana. Lastly, it's important
          to pick blessing stones that can benefit your entire team:
        </p>
        <ul>
          <li>
            Bloodlust is pretty much the best guild boss blessing stone,
            allowing you to decrease the defense of the boss just by attacking
            it
          </li>
          <li>
            We are as one is a powerful option to buff up your team 1 hunters,
            usually Cha and Go Gunhee.
          </li>
          <li>
            Reawakening is just as important as Bloodlust, further reducing your
            cooldown for maximum uptime on your skills;
          </li>
          <li>
            The last one is generally 'Swiftness' since it will give you one
            additional dash usage on top of a % to get your dash back when using
            it, allowing you to reposition yourself to trigger as many shadow
            steps as possible.
          </li>
        </ul>
        <SectionHeader title="Best Equipment for Sung Jinwoo - Armor" />
        <p>
          Jinwoo can equip a variety of sets. While you can arguably find usage
          for most of them (please don't use the noble sacrifice set) there are
          some sets that will work best in the majority of setups. You’ll mostly
          use those sets at the start, or while you’re still piecing together
          your ‘Meta Builds’ mentioned previously.
        </p>
        <h5>One-hit kill</h5>
        <Row xs={1} xxl={1} className="relic-set-container">
          <Col>
            <SoloArtifact id={4} />
          </Col>
        </Row>
        <p>
          One-hit kill (Almighty Kargalan) is popular for ultimate focused
          builds, since it not only increases the damage of the ultimate skill
          but also decreases the cooldown of it by a whoppin 40%. If you are
          using this set you can use a combination of Attack substats (
          additional and %) and defense penetration. You can also obviously use
          crit and crit damage substats for a better damage outside of your
          ultimate window.
        </p>
        <h5>Armed</h5>
        <Row xs={1} xxl={1} className="relic-set-container">
          <Col>
            <SoloArtifact id={3} />
          </Col>
        </Row>
        <p>
          Armed (Black Lion's Armor) is an amazing generalist set that gives you
          attack and defense ignore. It's in general a very strong set to use
          since it can easily be switched to your hunters when you get better
          pieces and the bonuses are universal. You can focus on a combination
          of attack substats (additional and %) and critical rate. Defense
          penetration also works, but you should probably run critical rate
          boots provided your crit is high enough.
        </p>
        <h5>Toughness</h5>
        <Row xs={1} xxl={1} className="relic-set-container">
          <Col>
            <SoloArtifact id={8} />
          </Col>
        </Row>
        <p>
          If you are running a crit focused build, Toughness (Hard Leathered) is
          the best set in the game, no questions asked. Being able to get
          critical AND critical damage at the same time as a set bonus is just
          invaluable. For players that are progressing and still building their
          Jinwoo, you can mix this set with Armed for a lot of free stats and
          then focus on the full set when you get better substats on the full
          set pieces.
        </p>
        <p>
          Considering the crit focus on this set you should aim for critical hit
          rate (as you want to hit the cap of 50% from stats), then
          attack%/additional attack and lastly defense penetration. Crit damage
          for the boots
        </p>
        <h5>Honorable mentions</h5>
        <p>
          If you get good pieces of High-Ranking Demon Armor (Destroyer set), it
          doesn't hurt to build them. You can slap them on a support so that
          with their 4/4 bonus they can improve your power generation
          significantly. Amazing if you are running 'One-Hit Kill' as a set!
          Solid Analysis could also be used against bosses that needs to be
          broken over and over, but it's such a niche set you would probably
          focus on different substats to run it on dedicated breakers like light
          Baek.
        </p>
        <SectionHeader title="Best Equipment for Sung Jinwoo - Jewelry" />
        <h5>Expert</h5>
        <Row xs={1} xxl={1} className="relic-set-container">
          <Col>
            <SoloJewelry id={3} />
          </Col>
        </Row>
        <p>
          Expert set is the be(a)st set for critical focused builds, as it gives
          a 50% chance to increase ATK by 1.6% for every crit. Obviously focus
          on critical hit rate and crit damage substats with this set, with
          attack%, additional damage, defense penetration and damage increase as
          consolation prizes.
        </p>
        <h5>Executioner / Berserker</h5>
        <Row xs={1} xxl={2} className="relic-set-container">
          <Col>
            <SoloJewelry id={5} />
          </Col>
          <Col>
            <SoloJewelry id={4} />
          </Col>
        </Row>
        <p>
          Executioner and Berserker are pretty much two faces of the same coin
          where the best set to use depends on the unit and scenario.
        </p>
        <ul>
          <li>
            Executioner increases the damage when the enemy is below 70% hp by
            20% and is pretty much a guaranteed power spike with no downside
            attached once you hit the threshold. The only time you might run
            into issues are if the bosses has a regeneration ability or if they
            are able to shield themselves, meaning it could take longer to take
            them below the threshold to benefit from the set.
          </li>
          <li>
            Berserker on the contrary gives you a 30% damage bonus when you go
            below 70% HP. Provided you are good at dodging and you don't risk
            getting one-shot by the enemy, this set offers a much bigger damage
            bonus for players that are confident in their dodging skills.
          </li>
          <ul>
            <li>
              If you are running A1 Schyte on Jinwoo, it's inevitable to see the
              synergy with the weapon, given you will pretty much get the
              critical hit bonus on top of the damage increase. Match made in
              heaven - or hell given the dark theme!
            </li>
          </ul>
        </ul>
        <p>
          For these two sets you can obviously go for a combination of damage
          increase, attack, critical hit/damage and defense penetration.
        </p>
        <h5>Honorable mention</h5>
        <p>
          While we are mostly discussing gear for Jinwoo, these three sets are
          pretty much a great fit for a lot of hunters as well. For example, if
          you are running a dark build on Jinwoo - as you probably are - you
          will overlap a lot of gear with SM Baek, given they both focus on
          critical stats and attack stats and Baek will get below 70% HP really
          quickly.
        </p>
        <p>
          While you obviously want to only upgrade past +10 gear with 2 or more
          usable stats don't be afraid to bring up to +10 gear with one usable
          substat as long as you already have a hunter in mind that could
          benefit from this, as it will help you progress much faster given you
          can repurpose the sets.
        </p>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default SoloGuidesJinwoo;

export const Head: React.FC = () => (
  <Seo
    title="Best stats and equipment for Jinwoo | Solo Leveling: Arise | Prydwen Institute"
    description="The best stats and equipment you should invest into on Sung Jinwoo in Solo Leveling: Arise."
    game="solo"
  />
);
